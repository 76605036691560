import Echo from "laravel-echo"
import axios from 'axios'

window.Pusher = require('pusher-js');

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.socket-more-connections-block')) {
        window.Echo = [];
        let token = document.head.querySelector('meta[name="csrf-token"]');
        if (token) {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
        } else {
            console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
        }
        var wsPort = 443;
        for (let index in window.tokens) {
            connectToSocket(248, null, window.tokens[index])
        }

        function connectToSocket(roomId, userId, token) {
            const config = {
                headers: {Authorization: `Bearer ${token}`}
            };
            axios.post(
                `/api/rooms/add-user/582445`,
                {},
                config
            );
            let options = {
                broadcaster: 'pusher',
                key: 'game',
                host: wsHost,
                authEndpoint: `/broadcasting/auth`,
                wsHost,
                wsPort,
                wssPort: wsPort,
                encrypted: wsPort === 443,
                forceTLS: wsPort === 443,
                disableStats: true,
                cluster: 'mt1',
                enabledTransports: ['ws', 'wss'],
                authorizer: (channel) => ({
                    authorize: async (socketId, callback) => {
                        try {
                            const options = {
                                channel_name: channel.name,
                                socket_id: socketId,
                            };
                            const config = {
                                headers: {Authorization: `Bearer ${token}`}
                            };
                            const authData = await axios.post(
                                `/broadcasting/auth`,
                                options,
                                config
                            );
                            callback(false, authData.data);
                        } catch (error) {
                            callback(true);
                        }
                    }
                })
            }
            window.Echo.push(new Echo(options));

            window.Echo[window.Echo.length - 1].private('chat-room.' + roomId)
                .listen('\\room.update', (e) => {
                    console.log(e);
                })
            // window.Echo.private('user-quiz.' + userId)
            //     .listen('\\user.quiz.checkAnswers', (e) => {
            //         console.log(e);
            //     })
        }
    }
});
