import Echo from "laravel-echo"
import axios from 'axios'

window.Pusher = require('pusher-js');

document.addEventListener('DOMContentLoaded', () => {
    let socketBlock = document.querySelector('.socket-block')
    let wsPort = 443
    if (window.Laravel) {
        let wsHost = window.Laravel.wsHost
        console.log(socketBlock)

        let token = document.head.querySelector('meta[name="csrf-token"]');
        if (token) {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
        } else {
            console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
        }
        if (socketBlock && window.Laravel.creator) {
            let roomPublic = window.Laravel.roomPublic

            if (!roomPublic) {
                if (window.Laravel.token) {
                    console.log(window.Laravel.token)
                    axios.get(`/api/rooms/show`, {
                        headers: {Authorization: `Bearer ${window.Laravel.token}`}
                    }).then(response => {
                            let data = response.data.data
                            console.log(data)
                            connectToSocket(data.id, data.creator.id, window.Laravel.token)
                        }
                    ).catch(error => {
                        console.log(error)
                            axios.post(`/api/rooms/create`, {}, {
                                headers: {Authorization: `Bearer ${window.Laravel.token}`}
                            }).then(function (response) {
                                    let data = response.data.data
                                    connectToSocket(data.id, data.creator.id, window.Laravel.token)
                                }
                            )
                        }
                    )
                } else {
                    axios
                        .post(`/api/rooms/create-with-user`)
                        .then(function (response) {
                            let data = response.data.data
                            console.log(data.user.id + ' ' + data.user.name);
                            console.log(data.room.code);
                            window.Laravel = {
                                userId: data.user.id,
                                creator: true
                            }
                            connectToSocket(data.room.id, data.user.id, data.token.accessToken)
                        })
                }
            }
        }

        if (!window.Laravel.creator) {
            axios
                .post(`/api/rooms/add-new-user/` + window.Laravel.code)
                .then(function (response) {
                    let data = response.data.data
                    console.log(data.token);
                    console.log(data.user);
                    console.log(data.room);
                    window.Laravel = {
                        userId: data.user.id,
                        creator: true
                    }
                    connectToSocket(data.room.id, data.user.id, data.token.accessToken)
                })
        }

        function connectToSocket(roomId, userId, token) {
            let options = {
                broadcaster: 'pusher',
                key: 'game',
                host: wsHost,
                authEndpoint: `/broadcasting/auth`,
                wsHost,
                wsPort,
                wssPort: wsPort,
                encrypted: wsPort === 443,
                forceTLS: wsPort === 443,
                disableStats: true,
                cluster: 'mt1',
                enabledTransports: ['ws', 'wss'],
                authorizer: (channel) => ({
                    authorize: async (socketId, callback) => {
                        try {
                            const options = {
                                channel_name: channel.name,
                                socket_id: socketId,
                            };
                            const config = {
                                headers: {Authorization: `Bearer ${token}`}
                            };
                            const authData = await axios.post(
                                `/broadcasting/auth`,
                                options,
                                config
                            );
                            callback(false, authData.data);
                        } catch (error) {
                            callback(true);
                        }
                    }
                })
            }
            window.Echo = new Echo(options);
            window.Echo.private('chat-room.' + roomId)
                .listen('\\room.update', (e) => {
                    console.log(e);
                })
                .listen('\\room.status', (e) => {
                    console.log(e);
                })
                .listen('\\room.newCreator', (e) => {
                    console.log(e);
                })
                .listen('\\user.update', (e) => {
                    console.log(e);
                })
                .listen('\\user.joining', (e) => {
                    console.log(e);
                })
                .listen('\\user.leaving', (e) => {
                    console.log(e);
                })
                .listen('\\room.quiz.question', (e) => {
                    console.log(e);
                })
                .listen('\\room.quiz.tourResultUpdate', (e) => {
                    console.log(e);
                })
                .listen('\\room.quiz.gameResultUpdate', (e) => {
                    console.log(e);
                })
            window.Echo.private('user.' + userId)
                .listen('\\user.quiz.checkAnswers', (e) => {
                    console.log(e);
                })
        }
    }
});
